@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.postedGameList {
    &__title {
        @include page-header;
        padding-bottom: $spacing-4;
    }

    &__list {
        @include flex-column;
        gap: $spacing-4;
    }
}

.add-game-cell {
    display: flex;
    align-items: center;
    gap: $spacing-4;
    margin-bottom: $spacing-4;

    &__img {
        width: 125px;
        height: 125px;
        background-color: rgba(246, 246, 246);
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        transition: all .15s ease;
    }

    &__CTA {
        @include label-or-button;
        color: $action;
        transition: all .1s ease;
    }
}

.add-game-cell:hover>.add-game-cell__img>.add-game-cell__icon {
    transform: scale(1.2);
}

.add-game-cell:hover>.add-game-cell__CTA {
    color: $action-hover;
}