@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.gameDetailsInfo {
    @include flex-column;
    align-items: center;
    width: 100%;
    padding: $spacing-4 $spacing-4;
    gap: $spacing-4;

    @include tablet {
        flex-direction: row;
        align-items: flex-start;
    }

    &__left {
        @include flex-column;
        width: 100%;
        flex-shrink: 0;
        gap: $spacing-4;

        @include tablet {
            max-width: 50%;
            padding-right: $spacing-4;
            position: sticky;
        }
    }

    &__button-and-instructions {
        width: 100%;
        flex-shrink: 0;
        @include flex-column;
        align-items: center;
        gap: $spacing-1;
    }

    &__instructions {
        @include caption-small;
        color: $secondary;
    }

    &__right {
        @include flex-column;
        gap: $spacing-2;
        padding-bottom: $spacing-4
    }

    &__overview {
        @include flex-column;
        padding: $spacing-2 0 $spacing-4;
    }

    &__reserve-button {
        @include button-styling;
        width: 100%;
        transition: background-color 275ms ease;

        &:hover {
            background-color: $action-hover;
            cursor: pointer;
        }

    }

    &__gamePhoto {
        
        height:  80%;
    }

    &__description {
        @include body-copy;
    }

    &__owner {
        @include body-copy;
        color: $secondary;
    }

    &__players-and-playtime {
        @include body-copy;
        color: $secondary;
    }

    &__title {
        @include page-header;
    }

    &__img-wrapper {
        border-radius: 6px;
        overflow: hidden;
        flex-shrink: 0;
    }

    &__title {
        margin: 0;
    }
}