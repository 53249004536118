@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.gameCommentsList {
    @include flex-column;
    gap: $spacing-3;

    &__title {
        margin-top: $spacing-4;
        @include subheader;
    }
}