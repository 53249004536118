// IMPORTS

@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.navBar {
    @include flex-column;
    align-items: center;
    width: 100%;

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 900px;

        @include tablet {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .active {
        color: black;
    }

    &__title {
        padding: $spacing-4;
        font-family: 'SFPro';
        font-weight: 600;
        font-size: 20px;
        color: black;
        text-decoration: none;

        & a {
            color: black;
        }
    }

    &__links {
        display: flex;
    }

    &__link {
        color: $primary;
        @include nav-link;
        padding: $spacing-2;
    }
}