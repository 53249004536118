@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.gameCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
    transition: transform 250ms;

    &:hover {
        transform: translateY(-10px);
    }

    @include tablet {
        width: 45%;
    }

    @include desktop {
        width: 31%;
        max-width: 400px;
    }

    &__img-container {
        height: 200px;
        width: 100%;
        @include flex-column;
        justify-content: center;
        align-items: center;
        position: relative;

    }

    &__img {
        height: 80%;
        padding: 10px;
    }

    &__gameName {
        @include subheader;
    }

    &__gameInfo {
        @include body-copy;
        color: $secondary;
    }

    &__text {
        padding: $spacing-4;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }



    &__top-fill {
        width: 100%;
        height: 100%;
        background-color: $subtle-bg;
        flex-shrink: 0;
        flex-grow: 0;
        position: absolute;
        opacity: 0.2;
    }
}