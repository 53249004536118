@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.gameCardList {
    @include flex-column;
    width: 100%;
    align-items: center;
    padding: $spacing-4;
    gap: $spacing-4;
    flex-wrap: wrap;

    @include tablet {
        padding: 0;
        flex-direction: row;
        justify-content: flex-start;
    }

}