@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.reservedGameList {
    &__title {
        @include page-header;
        padding-bottom: $spacing-4;
    }

    &__list {
        @include flex-column;
        gap: $spacing-4;
        padding-bottom: $spacing-8;
    }
}

