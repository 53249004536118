@use './variables' as *;
@use './typography' as *;

// SCREENSIZING MIXINS

@mixin tablet {
    @media screen and (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

//TYPOGRAPHY 

@mixin page-header() {
    font-family: "SFPro";
    font-size: 26px;
    line-height: 32px;
    font-weight: 600;
    margin: $spacing-4;

    @include tablet {
        margin: $spacing-4 0;
    }
}

@mixin subheader() {
    font-family: "SFPro";
    font-size: 21px;
    line-height: 26px;
    font-weight: 600;
    color: black;

    @include tablet {
        font-size: 1.1875rem;
        line-height: 1.5625rem;
    }
}

@mixin body-copy() {
    font-family: "SFPro";
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    @include tablet {}
}

@mixin label-or-button() {
    font-family: "SFPro";
    font-size: 13px;
    line-height: 18px;
    font-weight: bold;
}

@mixin caption-bold() {
    font-family: "SFPro";
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
}

@mixin caption-reg() {
    font-family: "SFPro";
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

@mixin caption-small() {
    font-family: "SFPro";
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
}

@mixin nav-link() {
    font-family: "SFPro";
    font-size: 20px;
    line-height: 1.25rem;
}

//UTILITY 

@mixin flex-column() {
    display: flex;
    flex-direction: column;
}

@mixin input-styling() {
    padding: 12px 16px;
    border: none;
    background-color: rgba($color: #000000, $alpha: 0.05);
    border-radius: 10px;
    @include body-copy;

    &::placeholder {
        color: rgba(0, 0, 0, 0.35);
    }

    width: 48%;
}

@mixin button-styling() {
    background-color: $action;
    padding: $spacing-3;
    border: none;
    border-radius: 6px;
    @include label-or-button;
    color: white;
}