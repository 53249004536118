@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.sign-in {
    &__container {
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    &__title {
        @include page-header;
        text-align:center
    }
    

}