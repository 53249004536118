@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.gameDetails {
    width: 100%;
    @include flex-column;
    align-items: center;

    &__content {
        max-width: 900px;
    }
}