@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.returnModal {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    top: 0;
    align-items: center;
    z-index: 100;

    &__reviewInput {
        @include input-styling;
        width: 100%;
        resize: none;
    }

    &__container {
        width: 90%;
        background-color: white;
        @include flex-column;

        max-width: 600px;
        padding: $spacing-4;
        gap: $spacing-4;
        border-radius: $spacing-1;

        @include tablet {
            width: 50%;
        }

        @include desktop {
            width: 35%;
        }
    }

    &__button-bar {
        display: flex;
        justify-content: flex-end;
    }

    &__confirm-button {
        @include button-styling;

        &:hover {
            background-color: $action-hover;
            cursor: pointer;
        }
    }

    &__cancel-button {
        @include button-styling;
        background-color: white;
        color: $action;

        &:hover {
            color: $action-hover;
            cursor: pointer;
        }
    }

    &__header {
        @include page-header;
    }
}