@use "../../styles/globals";
@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.add-board-game {
    width: 100%;
    @include flex-column;
    align-items: center;
    padding: 0 $spacing-4;

    &__container {
        max-width: 900px;
    }

    &__bgAutoComplete {
        @include input-styling
    }

    &__title {
        @include page-header;
    }

    &__listOfInputs {
        display: flex;
        flex-wrap: wrap;
        gap: 10px
    }

    &__bgName {
        @include input-styling();
        flex-grow: 1;
    }

    &__bgMinDuration {
        @include input-styling();
        flex-grow: 1;
    }

    &__bgMaxDuration {
        @include input-styling();
        flex-grow: 1;
    }

    &__bgMinPlayers {
        @include input-styling();
        flex-grow: 1;
    }

    &__bgMaxPlayers {
        @include input-styling();
        flex-grow: 1;
    }

    &__bgDescription {
        @include input-styling();
        width: 100%;
        height: 150px;
        resize: none
    }

    &__bgCondition {
        @include input-styling();
        flex-grow: 1;
        background-image: url('../../assets/icons/arrow_drop_down_FILL0_wght400_GRAD0_opsz48.png');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 20px 20px;

    }

    select {
        appearance: none;
    }

    &__button-bar {
        padding-top: $spacing-4;
        width: 100%;
        display: flex;
        justify-content: flex-end;

    }

    &__button {
        @include button-styling;
        width: 20%;
        transition: all 0.25s;

        &:hover {
            background-color: $action-hover;
            cursor: pointer;
        }
    }
}


.error {
    border: 2px solid red;
}